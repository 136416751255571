<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <b-img
            v-if="skin !== 'dark'"
            width="230px"
            src="@/assets/images/logo-text-black.png"
          />
          <b-img
            v-else
            width="230px"
            src="@/assets/images/logo-text-white.png"
          />
        </b-link>

        <b-card-title class="mb-1">
          Alterar Senha 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Sua nova senha deve ser diferente de senhas anteriores
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >

            <!-- password -->
            <b-form-group
              label="Nova senha"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="Senha"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirmar Senha"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirmar Senha"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              :disabled="loading"
              block
              type="submit"
              variant="primary"
            >
              <span v-if="!loading">Atualizar nova senha</span>
              <b-spinner
                v-else
                small
              />
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{name:'auth-login-v1'}">
            <feather-icon icon="ChevronLeftIcon" /> Voltar ao Login
          </b-link>
        </p>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BImg, BSpinner, BFormInput, BButton
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BImg,
    BSpinner,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      // validation
      required,
      loading: false,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password'
    }
  },
  computed: {
    password1ToggleIcon () {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon () {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    skin () {
      return store.state.appConfig.layout.skin
    }
  },
  mounted () {
    console.log(this.$router.currentRoute.query.token)
  },
  methods: {
    togglePassword1Visibility () {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility () {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm () {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true

          this.$http.post('/auth/reset_password_validation', {
            password: this.password,
            password_confirmation: this.cPassword,
            token: this.$router.currentRoute.query.token
          })
            // eslint-disable-next-line no-unused-vars
            .then(res => {
              if (res.data.message === 'Token not found') this.userNotFound()
              if (res.data.message === 'Token expired') this.invalidToken()
              if (res.data.message === 'Password updated') this.success()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    userNotFound () {
      this.$swal({
        title: 'Nenhum usuário encontrado.',
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    invalidToken () {
      this.$swal({
        title: 'Este link de recuperação de senha já expirou.',
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    success () {
      this.$swal({
        title: 'Senha alterada com sucesso!',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      }).then(() => {
        this.$router.push({ name: 'auth-login' })
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
